import React, { Component } from 'react';
import './Wishlist.css';
import Title from '../Layout/Title';

export default class Wishlist extends Component {
    constructor(props) {
        super(props);
        this.state = {
            'title': 'Lista de Deseos 🎁',
            wishes: [
                {
                    id: 1,
                    name: "Mouse Inalámbrico",
                    link: "https://www.logitech.com/es-roam/products/mice/mx-master-3s.910-006561.html",
                    giftedBy: "",
                    fulfilled: false,
                },
                {
                    id: 2,
                    name: "Introducción al Judo -  Miguel Villamón",
                    link: "https://www.buscalibre.cl/libro-introduccion-al-judo-herakles/9788425512964/p/3084550",
                    giftedBy: "Pachi y Ren",
                    fulfilled: true,
                },
                {
                    id: 3,
                    name: "Martillo de Goma",
                    link: "https://www.sodimac.cl/sodimac-cl/articulo/112773908/Combo-Martillo-Mazo-De-Goma-8oz/112773909?exp=so_com",
                    giftedBy: "",
                    fulfilled: false,
                },
                {
                    id: 3,
                    name: "Densho Kukishinden Ryu",
                    link: "https://www.amazon.com/Kukishinden-Ry%C3%BB-Bujinkan-Bud%C3%B4-Densho/dp/3924862206/",
                    giftedBy: "",
                    fulfilled: false,
                },
                {
                    id: 3,
                    name: "Encendedor Zippo",
                    link: "https://zippo.cl/productos/encendedores/",
                    giftedBy: "Paz y Renata",
                    fulfilled: true,
                },
            ],
        };
    }

    render() {
        return (
        <div>
                <Title dataFromParent={this.state.title} />
            <div className="min-h-screen bg-black text-sky-500 flex flex-col items-center p-6 bg-opacity-90 border-gray-700 border">
                <h1 className="text-3xl font-bold mb-6"></h1>
                <ul className="w-full max-w-2xl space-y-4">
                    {this.state.wishes.map((wish) => (
                        <li
                            key={wish.id}
                            className={`p-4 border rounded-md`}
                        >
                            <div>
                                <h2 className={`txt-lg font-semibold ${
                                        wish.fulfilled
                                            ? "border-sky-700 line-through"
                                            : "border-gray-700"
                                    }`}>{wish.name}</h2>
                                <a
                                    href={wish.link}
                                    target="_blank"
                                    rel="noopener noreferrer"
                                    className={`text-sky-400 hover:underline ${
                                        wish.fulfilled
                                            ? "border-sky-700 line-through"
                                            : "border-gray-700"
                                    }  `}
                                >
                                    Ver enlace
                                </a>
                                {wish.giftedBy && (
                                    <p className="text-sm text-gray-400">
                                        ¡ Gracias {" "}
                                        <span className="text-gray-100">{wish.giftedBy} !</span>
                                    </p>
                                )}
                            </div>
                        </li>
                    ))}
                </ul>
            </div>
            <div className="h-6"></div>
        </div>
        );
    }
}